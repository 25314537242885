AFRAME.registerComponent('decrease-sun-action', {
  init: function () {
    var el = this.el;
    el.addEventListener('click', function () {
      el.sceneEl.emit('lessSunshine', 10);
    });
  },
});

AFRAME.registerComponent('increase-sun-action', {
  init: function () {
    var el = this.el;
    el.addEventListener('click', function () {
      el.sceneEl.emit('moreSunshine', 10);
    });
  },
});
